// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.recruiter-navbar {
    display: flex;
    flex-wrap: wrap; /* Permet aux éléments de se plier sur plusieurs lignes */
    justify-content: center; /* ou 'space-around' si vous préférez */
    background-color: #f8f9fa; /* Couleur de fond de la navigation secondaire */
    padding: 10px; /* Ajustez selon vos préférences */
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1); /* Ombre pour distinguer du header principal */

  }
  
  .recruiter-navbar a {
    text-decoration: none;
    color: #333333; /* Couleur des liens */
    margin: 5px; /* Réduit l'espace entre les liens */
    padding: 5px 10px; /* Espacement interne pour chaque lien */
    border-radius: 4px; /* Rayon de bordure pour les effets de survol */
    white-space: nowrap; /* Évite le retour à la ligne pour les textes trop longs */

  }
  
  .recruiter-navbar a.active {
    background-color: #89baef; /* Couleur de fond pour l'élément actif */
    color: white; /* Couleur du texte pour l'élément actif */
  }

  @media (max-width: 768px) {
    .recruiter-navbar {
      padding: 5px; /* Réduit le padding sur les petits écrans */
    }
  
    .recruiter-navbar a {
      margin: 2px; /* Réduit l'espace entre les liens */
      font-size: 14px; /* Réduit la taille de la police */
    }
   
  }
  `, "",{"version":3,"sources":["webpack://./src/components/RecruiterNavbar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe,EAAE,yDAAyD;IAC1E,uBAAuB,EAAE,uCAAuC;IAChE,yBAAyB,EAAE,gDAAgD;IAC3E,aAAa,EAAE,kCAAkC;IACjD,0CAA0C,EAAE,8CAA8C;;EAE5F;;EAEA;IACE,qBAAqB;IACrB,cAAc,EAAE,sBAAsB;IACtC,WAAW,EAAE,oCAAoC;IACjD,iBAAiB,EAAE,wCAAwC;IAC3D,kBAAkB,EAAE,+CAA+C;IACnE,mBAAmB,EAAE,0DAA0D;;EAEjF;;EAEA;IACE,yBAAyB,EAAE,yCAAyC;IACpE,YAAY,EAAE,0CAA0C;EAC1D;;EAEA;IACE;MACE,YAAY,EAAE,4CAA4C;IAC5D;;IAEA;MACE,WAAW,EAAE,oCAAoC;MACjD,eAAe,EAAE,kCAAkC;IACrD;;EAEF","sourcesContent":[".recruiter-navbar {\n    display: flex;\n    flex-wrap: wrap; /* Permet aux éléments de se plier sur plusieurs lignes */\n    justify-content: center; /* ou 'space-around' si vous préférez */\n    background-color: #f8f9fa; /* Couleur de fond de la navigation secondaire */\n    padding: 10px; /* Ajustez selon vos préférences */\n    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1); /* Ombre pour distinguer du header principal */\n\n  }\n  \n  .recruiter-navbar a {\n    text-decoration: none;\n    color: #333333; /* Couleur des liens */\n    margin: 5px; /* Réduit l'espace entre les liens */\n    padding: 5px 10px; /* Espacement interne pour chaque lien */\n    border-radius: 4px; /* Rayon de bordure pour les effets de survol */\n    white-space: nowrap; /* Évite le retour à la ligne pour les textes trop longs */\n\n  }\n  \n  .recruiter-navbar a.active {\n    background-color: #89baef; /* Couleur de fond pour l'élément actif */\n    color: white; /* Couleur du texte pour l'élément actif */\n  }\n\n  @media (max-width: 768px) {\n    .recruiter-navbar {\n      padding: 5px; /* Réduit le padding sur les petits écrans */\n    }\n  \n    .recruiter-navbar a {\n      margin: 2px; /* Réduit l'espace entre les liens */\n      font-size: 14px; /* Réduit la taille de la police */\n    }\n   \n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
