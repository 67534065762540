// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.page-container{
  /* background: linear-gradient(to bottom, #2f4351, #6e7aa1); */
  background: linear-gradient(to bottom, #64a0c8, #6e7aa1);
  width: 101%;
  height: 100%;
  margin:auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.left-side{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #fff;
  margin: auto;
  margin-top: 180px;
  align-self: stretch; /* Make left-side stretch to match right-side height */
  margin-bottom: 30px;
}

.right-side{
  display: block;
  margin-top: 160px;
  margin-bottom: 30px;
}

@media screen and (max-width: 1030px){
  .page-container{
    background: linear-gradient(to bottom, #2f3551, #6e7aa1);
    width: 104%;
    height: 100%;
    margin:auto;
  }

  .toggleButton {
    position: absolute; /* Set the position to absolute */
    left: 0; 
    z-index: 99999;
    width: auto;
    height: auto;
    margin-left: 20px;
    margin-top: 130px;
    background-color: transparent;
    border: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/chat/Chat.css"],"names":[],"mappings":";AACA;EACE,8DAA8D;EAC9D,wDAAwD;EACxD,WAAW;EACX,YAAY;EACZ,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,sBAAsB;EACtB,mBAAmB;EACnB,sBAAsB;EACtB,YAAY;EACZ,iBAAiB;EACjB,mBAAmB,EAAE,sDAAsD;EAC3E,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE;IACE,wDAAwD;IACxD,WAAW;IACX,YAAY;IACZ,WAAW;EACb;;EAEA;IACE,kBAAkB,EAAE,iCAAiC;IACrD,OAAO;IACP,cAAc;IACd,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,iBAAiB;IACjB,6BAA6B;IAC7B,YAAY;EACd;AACF","sourcesContent":["\n.page-container{\n  /* background: linear-gradient(to bottom, #2f4351, #6e7aa1); */\n  background: linear-gradient(to bottom, #64a0c8, #6e7aa1);\n  width: 101%;\n  height: 100%;\n  margin:auto;\n  display: flex;\n  justify-content: center;\n  align-items: flex-start;\n}\n\n.left-side{\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  text-align: center;\n  background-color: #fff;\n  border-radius: 10px;\n  border: 1px solid #fff;\n  margin: auto;\n  margin-top: 180px;\n  align-self: stretch; /* Make left-side stretch to match right-side height */\n  margin-bottom: 30px;\n}\n\n.right-side{\n  display: block;\n  margin-top: 160px;\n  margin-bottom: 30px;\n}\n\n@media screen and (max-width: 1030px){\n  .page-container{\n    background: linear-gradient(to bottom, #2f3551, #6e7aa1);\n    width: 104%;\n    height: 100%;\n    margin:auto;\n  }\n\n  .toggleButton {\n    position: absolute; /* Set the position to absolute */\n    left: 0; \n    z-index: 99999;\n    width: auto;\n    height: auto;\n    margin-left: 20px;\n    margin-top: 130px;\n    background-color: transparent;\n    border: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
