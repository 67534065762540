// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.follower-container {
    display: flex;
    align-items: center;
    padding: 10px;
  }
  
  .online-dot {
    width: 10px;
    height: 10px;
    background-color: #7EF533;
    border-radius: 50%;
    margin-right: 10px;
    position: absolute;
    left: 3rem;
  }
  
  .follower-details {
    display: flex;
    flex-direction: row;
  }
 
  .follower-vertical {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 10px;
  }
  
  .follower-horizontal {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .followerImage {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  
  .name {
    font-size: 0.8rem;
    margin-bottom: 5px;
  }
  
  .online {
    font-size: 0.8rem;
    color: #7EF533;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/chat/Conversation.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,aAAa;EACf;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,kBAAkB;IAClB,kBAAkB;IAClB,UAAU;EACZ;;EAEA;IACE,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,uBAAuB;EACzB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,iBAAiB;IACjB,kBAAkB;EACpB;;EAEA;IACE,iBAAiB;IACjB,cAAc;EAChB","sourcesContent":[".follower-container {\n    display: flex;\n    align-items: center;\n    padding: 10px;\n  }\n  \n  .online-dot {\n    width: 10px;\n    height: 10px;\n    background-color: #7EF533;\n    border-radius: 50%;\n    margin-right: 10px;\n    position: absolute;\n    left: 3rem;\n  }\n  \n  .follower-details {\n    display: flex;\n    flex-direction: row;\n  }\n \n  .follower-vertical {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    margin-right: 10px;\n  }\n  \n  .follower-horizontal {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n  }\n  \n  .followerImage {\n    width: 50px;\n    height: 50px;\n    border-radius: 50%;\n  }\n  \n  .name {\n    font-size: 0.8rem;\n    margin-bottom: 5px;\n  }\n  \n  .online {\n    font-size: 0.8rem;\n    color: #7EF533;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
