// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.userEmploi-wrapper {
    background-color: white !important;
    min-height: 100vh;
  }
  
  body {
    background-color: white !important;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/UserEmplois.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;IAClC,iBAAiB;EACnB;;EAEA;IACE,kCAAkC;EACpC","sourcesContent":[".userEmploi-wrapper {\n    background-color: white !important;\n    min-height: 100vh;\n  }\n  \n  body {\n    background-color: white !important;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
