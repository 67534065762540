// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input{
    width: 200px;
}

.category-box{
    max-width: 300px;
    height: 30px;
    background-color: lightgray;
    border: 1px;
    border-radius: 5px;
    margin: 5px;
}

.category-box span{
    margin-right: 5px;
}`, "",{"version":3,"sources":["webpack://./src/pages_admin/AddEvent.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,2BAA2B;IAC3B,WAAW;IACX,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":["input{\n    width: 200px;\n}\n\n.category-box{\n    max-width: 300px;\n    height: 30px;\n    background-color: lightgray;\n    border: 1px;\n    border-radius: 5px;\n    margin: 5px;\n}\n\n.category-box span{\n    margin-right: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
