// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.star-icon {
  cursor: pointer;
  transition: transform 0.3s ease;

}

.star-icon:not(.clicked) {
  stroke: #808080; /* Gris pour l'état non cliqué */
}

.star-icon:hover {
  transform: scale(1.2);
}

.star-icon.clicked {
  fill: #FFD700; /* Jaune pour l'état cliqué */
  stroke: #FFD700; /* Ajustez également la couleur du stroke en jaune pour l'état cliqué */

}`, "",{"version":3,"sources":["webpack://./src/pages/CustomTagIcon.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,+BAA+B;;AAEjC;;AAEA;EACE,eAAe,EAAE,gCAAgC;AACnD;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,aAAa,EAAE,6BAA6B;EAC5C,eAAe,EAAE,uEAAuE;;AAE1F","sourcesContent":[".star-icon {\n  cursor: pointer;\n  transition: transform 0.3s ease;\n\n}\n\n.star-icon:not(.clicked) {\n  stroke: #808080; /* Gris pour l'état non cliqué */\n}\n\n.star-icon:hover {\n  transform: scale(1.2);\n}\n\n.star-icon.clicked {\n  fill: #FFD700; /* Jaune pour l'état cliqué */\n  stroke: #FFD700; /* Ajustez également la couleur du stroke en jaune pour l'état cliqué */\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
