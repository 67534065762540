import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './RecruiterNavbar.css'; 

const RecruiterNavbar = () => {
   

  return (
    
    <div className="recruiter-navbar">
     

     <NavLink to="/recruteur/analyse" activeClassName="active">Tableau de Bord</NavLink> 
      <NavLink to="/recruteur/dashboard" activeClassName="active">Mes Offres</NavLink>
      <NavLink to="/Chat" activeClassName="active">Communication </NavLink>
      <NavLink to="/recruteur/gestion-event/list" activeClassName="active">Gestion Evénement</NavLink> 
      <NavLink to="/recruteur/reclamation" activeClassName="active">Réclamation</NavLink>


    </div>
  );
};

export default RecruiterNavbar;