// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Message.css */

.message-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Center vertically on the page */
    background-color: rgba(181, 226, 250, 1); /* Blue background color */
  }
  
  .message-text {
    font-size: 24px; /* Increase font size */
    color: white; /* White text color */
    font-weight: bold; /* Bold text */
    text-align: center; /* Center the text */
    padding: 20px; /* Add padding for spacing */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
    max-width: 80%; /* Limit the width of the message */
    background-color: rgba(54, 159, 240, 0.911); /* Semi-transparent black background */
  }
  
  .message-text::selection {
    background-color: transparent; /* Remove selection background color */
  }
  
  .accueil{
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 10rem;
    margin-right: 2rem;
  }`, "",{"version":3,"sources":["webpack://./src/pages/Abonnement/Message.css"],"names":[],"mappings":"AAAA,gBAAgB;;AAEhB;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa,EAAE,kCAAkC;IACjD,wCAAwC,EAAE,0BAA0B;EACtE;;EAEA;IACE,eAAe,EAAE,uBAAuB;IACxC,YAAY,EAAE,qBAAqB;IACnC,iBAAiB,EAAE,cAAc;IACjC,kBAAkB,EAAE,oBAAoB;IACxC,aAAa,EAAE,4BAA4B;IAC3C,mBAAmB,EAAE,oBAAoB;IACzC,0CAA0C,EAAE,wBAAwB;IACpE,cAAc,EAAE,mCAAmC;IACnD,2CAA2C,EAAE,sCAAsC;EACrF;;EAEA;IACE,6BAA6B,EAAE,sCAAsC;EACvE;;EAEA;IACE,kBAAkB;IAClB,MAAM;IACN,QAAQ;IACR,iBAAiB;IACjB,kBAAkB;EACpB","sourcesContent":["/* Message.css */\n\n.message-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh; /* Center vertically on the page */\n    background-color: rgba(181, 226, 250, 1); /* Blue background color */\n  }\n  \n  .message-text {\n    font-size: 24px; /* Increase font size */\n    color: white; /* White text color */\n    font-weight: bold; /* Bold text */\n    text-align: center; /* Center the text */\n    padding: 20px; /* Add padding for spacing */\n    border-radius: 10px; /* Rounded corners */\n    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */\n    max-width: 80%; /* Limit the width of the message */\n    background-color: rgba(54, 159, 240, 0.911); /* Semi-transparent black background */\n  }\n  \n  .message-text::selection {\n    background-color: transparent; /* Remove selection background color */\n  }\n  \n  .accueil{\n    position: absolute;\n    top: 0;\n    right: 0;\n    margin-top: 10rem;\n    margin-right: 2rem;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
