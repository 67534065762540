import React, { useState, useEffect } from 'react';
import '../EspaceRecruteur/CandidatDetails.css';
import { faUser, faAddressCard, faPhone, faEnvelope, faLocationDot, faFlag, faBirthdayCake,faComments  } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import DOMPurify from 'dompurify';
import { useNavigate} from 'react-router-dom';


// export const baseURL = 'http://127.0.0.1:8000';

const DetailsCandidature = ({ applicationId  }) => {
  const [candidatureDetails, setCandidatureDetails] = useState(null); 
  const navigate = useNavigate();   


  useEffect(() => {
    console.log(`URL applicationId param: ${applicationId}`);

    const fetchData = async () => {
      try {
      // Fetch candidature details using the id directly
      console.log(`Fetching details for application ID: ${applicationId}`);

      const response = await axios.get(`/application/${applicationId}/details/`);
      console.log("API Response:", response.data);
        setCandidatureDetails(response.data);

      } catch (error) {
        console.error("Erreur lors de la récupération des détails de la candidature:", error);
        toast.error('Erreur lors de la récupération des détails du candidat.');
      }
    };

    fetchData();
  }, [applicationId ]);

  if (!candidatureDetails) {
    return <div>Chargement des détails...</div>;
  }
  

  const handleChatRedirect = () => {
    navigate(`/chat/`);
  };


// Désactivation des boutons en fonction du statut
const isAccepted = candidatureDetails && candidatureDetails.status === 'accepted';
const isRejected = candidatureDetails && candidatureDetails.status === 'rejected';
  
  return (
    <div >
      <ToastContainer
       
      />
    

<div className="container mt-5">
        <div className="row">

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
          {/* <h1>Détails de candidature</h1> */}
        </div>

         <div className=" col-12">
          {candidatureDetails.image_emploi && (
            <img className="candidate-image-circle" src={candidatureDetails.image_emploi} alt="logo de l'entreprise" />
          )}
         </div>
        </div>
         <br/> <br/>
         <div className="row gutters soustitres"  style={{marginLeft:"5px"}}>

             <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <h4 className="mt-3 mb-2">Information Général</h4>
                <hr className="border border-1 custom-hr" />
             </div>
             
             <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
  <div className="form-group">
    &nbsp;&nbsp;
    <strong>Description de l'emploi: </strong>
    <div 
      style={{ color: "black", fontSize: "18px", flex: 1 }} 
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(candidatureDetails.description) }}
    ></div>
  </div>
</div>
         {/* style={{ flex: 1 }} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(candidatureDetails.description) }}>  */}
             <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
               <div className="form-group">
               <FontAwesomeIcon icon={faLocationDot} style={{ color: "black" }} />&nbsp;&nbsp;
               <strong>Lieu: </strong> <span style={{ color: "black", fontSize: "18px" }}>{candidatureDetails.localisation}</span>
              </div>
             </div>
      
         </div>

         <div className="row gutters soustitres" style={{marginLeft:"5px"}}>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <h4 className="mt-3 mb-2">Contact</h4>
                            <hr className="border border-1 custom-hr" />
                        </div>
                        
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                            <div className="form-group">
                                <FontAwesomeIcon icon={faEnvelope} style={{ color: "black" }} />&nbsp;&nbsp;
                                <strong>Email: </strong>
                                {/* <span style={{ color: "black",fontSize:"18px" }}>{candidatureDetails.email}</span> */}
                            </div>
                            </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                <div className="form-group">
                                <FontAwesomeIcon icon={faPhone} style={{ color: "black" }} />&nbsp;&nbsp;
                                <strong>Numéro de Téléphone: </strong>
                                {/* <span style={{ color: "black",fontSize:"18px" }}>{candidatureDetails.numero_telephone}</span> */}
                                </div>
                            </div>
                        </div>

<div className="row mt-4 justify-content-center">
            <div className="col text-center">
              <button className="btn btn-info" style={{ maxWidth: "200px", width: "100%" }} onClick={handleChatRedirect}>
                <FontAwesomeIcon icon={faComments} />&nbsp; Communiquer
              </button>
            </div>
          </div>
      </div>
    </div>
  );
};

export default DetailsCandidature;
