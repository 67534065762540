// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `tr:nth-child(even) {
    background-color: white; 
}

tr:nth-child(odd) {
    background-color: #e7e0e0; 
}

th {
    background-color: #fff;
    color: #333;
}

.link-style {
    text-decoration: underline;
    font-weight: normal;
    width: auto;
    height: 20px;
    padding-left: 5px;
    padding-right: 5px;
    background-color: transparent;
    border: none; /* Set the regular border style */
    cursor: pointer;
    display: flex;
    justify-content: center; /* Horizontally center the content */
    align-items: center;
    color: #2DAAE1;
}

.link-style:hover {
    color: black; 
    text-decoration: none;
}

.date-input{
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.active-page-button {
    background-color: black;
  }`, "",{"version":3,"sources":["webpack://./src/pages_admin/Emplois.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;AAC3B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,sBAAsB;IACtB,WAAW;AACf;;AAEA;IACI,0BAA0B;IAC1B,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,6BAA6B;IAC7B,YAAY,EAAE,iCAAiC;IAC/C,eAAe;IACf,aAAa;IACb,uBAAuB,EAAE,oCAAoC;IAC7D,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,qBAAqB;AACzB;;AAEA;IACI,cAAc;IACd,WAAW;IACX,mCAAmC;IACnC,yBAAyB;IACzB,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,cAAc;IACd,sBAAsB;IACtB,4BAA4B;IAC5B,yBAAyB;IACzB,sBAAsB;IACtB,wEAAwE;AAC5E;;AAEA;IACI,uBAAuB;EACzB","sourcesContent":["tr:nth-child(even) {\n    background-color: white; \n}\n\ntr:nth-child(odd) {\n    background-color: #e7e0e0; \n}\n\nth {\n    background-color: #fff;\n    color: #333;\n}\n\n.link-style {\n    text-decoration: underline;\n    font-weight: normal;\n    width: auto;\n    height: 20px;\n    padding-left: 5px;\n    padding-right: 5px;\n    background-color: transparent;\n    border: none; /* Set the regular border style */\n    cursor: pointer;\n    display: flex;\n    justify-content: center; /* Horizontally center the content */\n    align-items: center;\n    color: #2DAAE1;\n}\n\n.link-style:hover {\n    color: black; \n    text-decoration: none;\n}\n\n.date-input{\n    display: block;\n    width: 100%;\n    height: calc(1.5em + 0.75rem + 2px);\n    padding: 0.375rem 0.75rem;\n    font-size: 1rem;\n    font-weight: 400;\n    line-height: 1.5;\n    color: #495057;\n    background-color: #fff;\n    background-clip: padding-box;\n    border: 1px solid #ced4da;\n    border-radius: 0.25rem;\n    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;\n}\n\n.active-page-button {\n    background-color: black;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
