// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.secteur-card {
    margin: 20px 0;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    transition: 0.3s;
    border-radius: 10px;
  }
  
  .secteur-card:hover {
    box-shadow: 0 8px 16px rgba(0,0,0,0.2);
  }
  
  .secteur-card h3 {
    margin-top: 0;
    color: #333;
  }
  
  .secteur-card p {
    color: #666;
  }
  
  .secteur-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

  .tableSecteur {
    background-color: #fff; /* Couleur d'arrière-plan du tableau */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Ombre portée pour un effet "flottant" */
  }
  
  .tableSecteur th {
    background-color: #007bff; /* Couleur d'arrière-plan des en-têtes */
    color: white; /* Couleur du texte des en-têtes */
  }
  
  .tableSecteur-hover tbody tr:hover {
    background-color: #f0f0f0; /* Couleur d'arrière-plan au survol des lignes */
  }`, "",{"version":3,"sources":["webpack://./src/pages_admin/Secteur.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,aAAa;IACb,qCAAqC;IACrC,gBAAgB;IAChB,mBAAmB;EACrB;;EAEA;IACE,sCAAsC;EACxC;;EAEA;IACE,aAAa;IACb,WAAW;EACb;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,gBAAgB;EAClB;;EAEA;IACE,sBAAsB,EAAE,sCAAsC;IAC9D,uCAAuC,EAAE,0CAA0C;EACrF;;EAEA;IACE,yBAAyB,EAAE,wCAAwC;IACnE,YAAY,EAAE,kCAAkC;EAClD;;EAEA;IACE,yBAAyB,EAAE,gDAAgD;EAC7E","sourcesContent":[".secteur-card {\n    margin: 20px 0;\n    padding: 20px;\n    box-shadow: 0 4px 8px rgba(0,0,0,0.1);\n    transition: 0.3s;\n    border-radius: 10px;\n  }\n  \n  .secteur-card:hover {\n    box-shadow: 0 8px 16px rgba(0,0,0,0.2);\n  }\n  \n  .secteur-card h3 {\n    margin-top: 0;\n    color: #333;\n  }\n  \n  .secteur-card p {\n    color: #666;\n  }\n  \n  .secteur-actions {\n    display: flex;\n    justify-content: space-between;\n    margin-top: 20px;\n  }\n\n  .tableSecteur {\n    background-color: #fff; /* Couleur d'arrière-plan du tableau */\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Ombre portée pour un effet \"flottant\" */\n  }\n  \n  .tableSecteur th {\n    background-color: #007bff; /* Couleur d'arrière-plan des en-têtes */\n    color: white; /* Couleur du texte des en-têtes */\n  }\n  \n  .tableSecteur-hover tbody tr:hover {\n    background-color: #f0f0f0; /* Couleur d'arrière-plan au survol des lignes */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
