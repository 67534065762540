export const LOGIN_SUCCESS= 'LOGIN_SUCCESS';
export const LOGIN_FAIL= 'LOGIN_FAIL';
export const SIGNUP_SUCCESS= 'SIGNUP_SUCCESS';
export const SIGNUP_FAIL= 'SIGNUP_FAIL';
export const ACTIVATION_SUCCESS= 'ACTIVATION_SUCCESS';
export const ACTIVATION_FAIL= 'ACTIVATION_FAIL';
export const USER_LOADED_SUCCESS= 'USER_LOADED_SUCCESS';
export const USER_LOADED_FAIL= 'USER_LOADED_FAIL';
export const AUTHENTICATED_SUCCESS= 'AUTHENTICATED_SUCCESS';
export const AUTHENTICATED_FAIL= 'AUTHENTICATED_FAIL';
export const PASSWORD_RESET_SUCCESS= 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_FAIL= 'PASSWORD_RESET_FAIL';
export const PASSWORD_RESET_CONFIRM_SUCCESS= 'PASSWORD_RESET_CONFIRM_SUCCESS';
export const PASSWORD_RESET_CONFIRM_FAIL= 'PASSWORD_RESET_CONFIRM_FAIL';
export const LOGOUT= 'LOGOUT';
export const AJOUTER_COMPETENCE_SUCCESS='AJOUTER_COMPETENCE_SUCCESS';
export const AJOUTER_COMPETENCE_FAIL='AJOUTER_COMPETENCE_FAIL';
export const MODIFICATION_SUCCESS='MODIFICATION_SUCCESS';
export const MODIFICATION_FAIL='MODIFICATION_FAIL';
export const RATING_CONDIDAT_SUCCESS='RATING_CONDIDAT_SUCCESS';
export const RATING_CONDIDAT_FAIL='RATING_CONDIDAT_FAIL';
export const SUPPRIMER_COMPETENCE_SUCCESS='SUPPRIMER_COMPETENCE_SUCCESS';
export const SUPPRIMER_COMPETENCE_FAIL='SUPPRIMER_COMPETENCE_FAIL';
export const RATING_SOCIETE_SUCCESS="RATING_SOCIETE_SUCCESS";
export const RATING_SOCIETE_FAIL="RATING_SOCIETE_FAIL";

