// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LogoSearch {
    display: flex;
    gap: 0.75rem;
  }
  .Search {
    display: flex;
    background-color: var(--inputColor);
    border-radius: 10px;
    padding: 5px;
  }
  .Search>input {
    background-color: transparent;
    border: none;
    outline: none;
  }
  .s-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(106.23deg, #f99827 0%, #f95f35 100%);
    border-radius: 5px;
    padding: 4px;
    color: white;
  }`, "",{"version":3,"sources":["webpack://./src/pages/chat/LogoSearch.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;EACd;EACA;IACE,aAAa;IACb,mCAAmC;IACnC,mBAAmB;IACnB,YAAY;EACd;EACA;IACE,6BAA6B;IAC7B,YAAY;IACZ,aAAa;EACf;EACA;IACE,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,gEAAgE;IAChE,kBAAkB;IAClB,YAAY;IACZ,YAAY;EACd","sourcesContent":[".LogoSearch {\n    display: flex;\n    gap: 0.75rem;\n  }\n  .Search {\n    display: flex;\n    background-color: var(--inputColor);\n    border-radius: 10px;\n    padding: 5px;\n  }\n  .Search>input {\n    background-color: transparent;\n    border: none;\n    outline: none;\n  }\n  .s-icon {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background: linear-gradient(106.23deg, #f99827 0%, #f95f35 100%);\n    border-radius: 5px;\n    padding: 4px;\n    color: white;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
