// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.containerabon{
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    width: 480px;
    height: 150px;
    background-color: transparent; /* Remove the white background */
    margin-top: 50px;
    margin-left: auto; /* Center horizontally */
    margin-right: auto; /* Center horizontally */
    margin-bottom: 5px;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.2);
  }
  
  .card h3 {
    font-size: 24px;
    padding-right: 20px;
}

.card h5 {
    font-size: 18px; /* Remove the extra font-size declaration */
}

.buttoncheckout {
    font-size: 18px;
    padding: 10px;
    background-color: #73dbf8;
    color: white;
    border: none;
    margin-left: 50px;
    width: 482px;
    box-shadow: 1px 2px 3px rgba(217, 14, 14, 0.2);
    
}
.containerabon .card .buttoncheckout:hover{
    background-color: #73dbf8;
    color: white;
    border: none;

}


  /* ... (rest of your styles) */
  `, "",{"version":3,"sources":["webpack://./src/pages/Abonnement/Classique.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB,EAAE,wBAAwB;IACjD,mBAAmB,EAAE,sBAAsB;IAC3C,YAAY;IACZ,aAAa;IACb,6BAA6B,EAAE,gCAAgC;IAC/D,gBAAgB;IAChB,iBAAiB,EAAE,wBAAwB;IAC3C,kBAAkB,EAAE,wBAAwB;IAC5C,kBAAkB;IAClB,0CAA0C;EAC5C;;EAEA;IACE,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,eAAe,EAAE,2CAA2C;AAChE;;AAEA;IACI,eAAe;IACf,aAAa;IACb,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,iBAAiB;IACjB,YAAY;IACZ,8CAA8C;;AAElD;AACA;IACI,yBAAyB;IACzB,YAAY;IACZ,YAAY;;AAEhB;;;EAGE,8BAA8B","sourcesContent":[".containerabon{\n    display: flex;\n    justify-content: center; /* Center horizontally */\n    align-items: center; /* Center vertically */\n    width: 480px;\n    height: 150px;\n    background-color: transparent; /* Remove the white background */\n    margin-top: 50px;\n    margin-left: auto; /* Center horizontally */\n    margin-right: auto; /* Center horizontally */\n    margin-bottom: 5px;\n    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.2);\n  }\n  \n  .card h3 {\n    font-size: 24px;\n    padding-right: 20px;\n}\n\n.card h5 {\n    font-size: 18px; /* Remove the extra font-size declaration */\n}\n\n.buttoncheckout {\n    font-size: 18px;\n    padding: 10px;\n    background-color: #73dbf8;\n    color: white;\n    border: none;\n    margin-left: 50px;\n    width: 482px;\n    box-shadow: 1px 2px 3px rgba(217, 14, 14, 0.2);\n    \n}\n.containerabon .card .buttoncheckout:hover{\n    background-color: #73dbf8;\n    color: white;\n    border: none;\n\n}\n\n\n  /* ... (rest of your styles) */\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
